<template>
  <section
    id="home"
    class="pa-0"
  > 
    <guest-background 
      :background_img="background_img"
      :toggle_bg_effect="toggle_bg_effect"
    />
    <guest-toolbar
      :company_name="company_name"
      :logo_img="logo_img"
    />
    <guest-login />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'Panel de administración - Guest'
      }
    },

    components: {
      GuestToolbar: () => import('@/components/sections/Guest/GuestToolbar'),
      GuestLogin: () => import('@/components/sections/Guest/GuestLogin'),
      GuestBackground: () => import('@/components/sections/Guest/GuestBackground')
    }, 
    data: () => ({
      background_img: 'https://i.imgur.com/rovasYS.jpg',//'/static/background_image.png',
      logo_img: '/static/image_logo_white.png',
      company_name: 'MARTEL',
      toggle_bg_effect: true
    }),

    computed: {
      cardFeatures () {
        return []
      },
      listFeatures () {
        return []
      },
      testimonials () {
        return []
      }
    }
  }
</script>
